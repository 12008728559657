import React from 'react';
import './LandingPage.css';
import logo from './logo.png';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <img src={logo} alt="Logo" className="logo" />
      <p className="summary"> 
      Come watch me on Twitch. <a href="https://www.twitch.tv/ravan_tv" target="_blank">Click Here</a> Giveaway to be announced soon!
      </p>
      <div className="twitch-embed">
        {/* Twitch Stream */}
        <div className="twitch-stream">
          <iframe
            src="https://player.twitch.tv/?channel=ravan_tv&parent=ravan.gg"
            height="480"
            width="854"
            allowFullScreen={true}
          ></iframe>
        </div>
        
        {/* Twitch Chat */}
        <div className="twitch-chat">
          <iframe
            src="https://www.twitch.tv/embed/ravan_tv/chat?parent=ravan.gg"
            height="500"
            width="350"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
